import React from 'react';
import { NavLink } from 'react-router-dom';
import { FeatherIconLayout } from "../icons/FeatherIconLayout";

class Sidebar extends React.Component {
  render() {
    const admin_email = localStorage.getItem('admin_id');
    // console.log(admin_email);

    return (
      <div className="div-2 col-md-2">
        <div className="overlap-2">
          <NavLink to="/Dashboard" activeClassName="active">
            <div className="element-navigations-menus">
              <div className="element-navigations-menus-wrapper">
                <div className="list-item-wrapper" style={{ top: "25px" }}>
                  <div className="list-item">Dashboard</div>
                </div>
              </div>
            </div>
          </NavLink>
        
          {admin_email === "66d95039e77d482ca9e51344" ? (
            <>
              <NavLink to="/Users" activeClassName="active">
                <div className="element-navigations-menus-4">
                  <div className="element-navigations-menus-3">
                    <div className="text-wrapper-3">Users</div>
                    <img 
                      className="img-2"
                      alt="Feather icon users"
                      src={require('../assets/img/feather-icon-users.svg').default}
                    />
                  </div>
                  <img
                    className="feather-icon-chevron"
                    alt="Feather icon chevron"
                    src={require('../assets/img/feather-icon-chevron-down-2.svg').default}
                  />
                </div>
              </NavLink>

              <NavLink to="/Theatres" activeClassName="active">
                <div className="element-navigations-menus-5">
                  <div className="element-navigations-menus-3">
                    <div className="text-wrapper-3">Theatres</div>
                    <img
                      className="img-2"
                      alt="Feather icon users"
                      src={require('../assets/img/feather-icon-users.svg').default}
                    />
                  </div>
                  <img
                    className="feather-icon-chevron"
                    alt="Feather icon chevron"
                    src={require('../assets/img/feather-icon-chevron-down-2.svg').default}
                  />
                </div>
              </NavLink>

              <NavLink to="/Groups" activeClassName="active">
                <div className="element-navigations-menus-6">
                  <div className="element-navigations-menus-3">
                    <div className="text-wrapper-3">Genres</div>
                    <img
                      className="img-2"
                      alt="Feather icon users"
                      src={require('../assets/img/feather-icon-users.svg').default}
                    />
                  </div>
                  <img
                    className="feather-icon-chevron"
                    alt="Feather icon chevron"
                    src={require('../assets/img/feather-icon-chevron-down-2.svg').default}
                  />
                </div>
              </NavLink>
              <NavLink to="/Theatrefacilities" activeClassName="active">
                <div className="element-navigations-menus-7">
                  <div className="element-navigations-menus-3">
                    <div className="text-wrapper-3">Theatre Facilities</div>
                    <img
                      className="img-2"
                      alt="Feather icon users"
                      src={require('../assets/img/feather-icon-users.svg').default}
                    />
                  </div>
                  <img
                    className="feather-icon-chevron"
                    alt="Feather icon chevron"
                    src={require('../assets/img/feather-icon-chevron-down-2.svg').default}
                  />
                </div>
              </NavLink>

              <NavLink to="/Movietype" activeClassName="active">
                <div className="element-navigations-menus-8">
                  <div className="element-navigations-menus-3">
                    <div className="text-wrapper-3">Movie Types</div>
                    <img
                      className="img-2"
                      alt="Feather icon users"
                      src={require('../assets/img/feather-icon-users.svg').default}
                    />
                  </div>
                  <img
                    className="feather-icon-chevron"
                    alt="Feather icon chevron"
                    src={require('../assets/img/feather-icon-chevron-down-2.svg').default}
                  />
                </div>
              </NavLink>
              <NavLink to="/Moviecert" activeClassName="active">
                <div className="element-navigations-menus-9">
                  <div className="element-navigations-menus-3">
                    <div className="text-wrapper-3">Movie Ceritificates</div>
                    <img
                      className="img-2"
                      alt="Feather icon users"
                      src={require('../assets/img/feather-icon-users.svg').default}
                    />
                  </div>
                  <img
                    className="feather-icon-chevron"
                    alt="Feather icon chevron"
                    src={require('../assets/img/feather-icon-chevron-down-2.svg').default}
                  />
                </div>
              </NavLink>
              <NavLink to="/Movielang" activeClassName="active">
                <div className="element-navigations-menus-10">
                  <div className="element-navigations-menus-3">
                    <div className="text-wrapper-3">Movie Language</div>
                    <img
                      className="img-2"
                      alt="Feather icon users"
                      src={require('../assets/img/feather-icon-users.svg').default}
                    />
                  </div>
                  <img
                    className="feather-icon-chevron"
                    alt="Feather icon chevron"
                    src={require('../assets/img/feather-icon-chevron-down-2.svg').default}
                  />
                </div>
              </NavLink>
              <NavLink to="/Movies" activeClassName="active">
                <div className="element-navigations-menus-11">
                  <div className="element-navigations-menus-3">
                    <div className="text-wrapper-3">Movies</div>
                    <img
                      className="img-2"
                      alt="Feather icon users"
                      src={require('../assets/img/feather-icon-users.svg').default}
                    />
                  </div>
                  <img
                    className="feather-icon-chevron"
                    alt="Feather icon chevron"
                    src={require('../assets/img/feather-icon-chevron-down-2.svg').default}
                  />
                </div>
              </NavLink>
              <NavLink to="/Moviecast" activeClassName="active">
                <div className="element-navigations-menus-12">
                  <div className="element-navigations-menus-3">
                    <div className="text-wrapper-3">Movie Casts</div>
                    <img
                      className="img-2"
                      alt="Feather icon users"
                      src={require('../assets/img/feather-icon-users.svg').default}
                    />
                  </div>
                  <img
                    className="feather-icon-chevron"
                    alt="Feather icon chevron"
                    src={require('../assets/img/feather-icon-chevron-down-2.svg').default}
                  />
                </div>
              </NavLink>
              <NavLink to="/Moviecrew" activeClassName="active">
                <div className="element-navigations-menus-13">
                  <div className="element-navigations-menus-3">
                    <div className="text-wrapper-3">Movie Crews</div>
                    <img
                      className="img-2"
                      alt="Feather icon users"
                      src={require('../assets/img/feather-icon-users.svg').default}
                    />
                  </div>
                  <img
                    className="feather-icon-chevron"
                    alt="Feather icon chevron"
                    src={require('../assets/img/feather-icon-chevron-down-2.svg').default}
                  />
                </div>
              </NavLink>


              <NavLink to="/Addon" activeClassName="active">
                <div className="element-navigations-menus-14">
                  <div className="element-navigations-menus-3">
                    <div className="text-wrapper-3">Addons</div>
                    <img
                      className="img-2"
                      alt="Feather icon users"
                      src={require('../assets/img/feather-icon-users.svg').default}
                    />
                  </div>
                  <img
                    className="feather-icon-chevron"
                    alt="Feather icon chevron"
                    src={require('../assets/img/feather-icon-chevron-down-2.svg').default}
                  />
                </div>
              </NavLink>

              <NavLink to="/Coupons" activeClassName="active">
                <div className="element-navigations-menus-15">
                  <div className="element-navigations-menus-3">
                    <div className="text-wrapper-3">Coupons</div>
                    <img
                      className="img-2"
                      alt="Feather icon users"
                      src={require('../assets/img/feather-icon-users.svg').default}
                    />
                  </div>
                  <img
                    className="feather-icon-chevron"
                    alt="Feather icon chevron"
                    src={require('../assets/img/feather-icon-chevron-down-2.svg').default}
                  />
                </div>
              </NavLink>
            </>
          ) : (
            <>

        
             
              <NavLink to="/Showtimings" activeClassName="active">
                <div className="element-navigations-menus-4">
                  <div className="element-navigations-menus-3">
                    <div className="text-wrapper-3">Show Timings</div>
                    <img
                      className="img-2"
                      alt="Feather icon users"
                      src={require('../assets/img/feather-icon-users.svg').default}
                    />
                  </div>
                  <img
                    className="feather-icon-chevron"
                    alt="Feather icon chevron"
                    src={require('../assets/img/feather-icon-chevron-down-2.svg').default}
                  />
                </div>
              </NavLink>
              <NavLink to="/Theatres" activeClassName="active">
                <div className="element-navigations-menus-5">
                  <div className="element-navigations-menus-3">
                    <div className="text-wrapper-3">Theatres</div>
                    <img
                      className="img-2"
                      alt="Feather icon users"
                      src={require('../assets/img/feather-icon-users.svg').default}
                    />
                  </div>
                  <img
                    className="feather-icon-chevron"
                    alt="Feather icon chevron"
                    src={require('../assets/img/feather-icon-chevron-down-2.svg').default}
                  />
                </div>
              </NavLink>

              <NavLink to="/Groups" activeClassName="active">
                <div className="element-navigations-menus-6">
                  <div className="element-navigations-menus-3">
                    <div className="text-wrapper-3">Genres</div>
                    <img
                      className="img-2"
                      alt="Feather icon users"
                      src={require('../assets/img/feather-icon-users.svg').default}
                    />
                  </div>
                  <img
                    className="feather-icon-chevron"
                    alt="Feather icon chevron"
                    src={require('../assets/img/feather-icon-chevron-down-2.svg').default}
                  />
                </div>
              </NavLink>
              <NavLink to="/Theatrefacilities" activeClassName="active">
                <div className="element-navigations-menus-7">
                  <div className="element-navigations-menus-3">
                    <div className="text-wrapper-3">Theatre Facilities</div>
                    <img
                      className="img-2"
                      alt="Feather icon users"
                      src={require('../assets/img/feather-icon-users.svg').default}
                    />
                  </div>
                  <img
                    className="feather-icon-chevron"
                    alt="Feather icon chevron"
                    src={require('../assets/img/feather-icon-chevron-down-2.svg').default}
                  />
                </div>
              </NavLink>

              <NavLink to="/Movietype" activeClassName="active">
                <div className="element-navigations-menus-8">
                  <div className="element-navigations-menus-3">
                    <div className="text-wrapper-3">Movie Types</div>
                    <img
                      className="img-2"
                      alt="Feather icon users"
                      src={require('../assets/img/feather-icon-users.svg').default}
                    />
                  </div>
                  <img
                    className="feather-icon-chevron"
                    alt="Feather icon chevron"
                    src={require('../assets/img/feather-icon-chevron-down-2.svg').default}
                  />
                </div>
              </NavLink>
              <NavLink to="/Moviecert" activeClassName="active">
                <div className="element-navigations-menus-9">
                  <div className="element-navigations-menus-3">
                    <div className="text-wrapper-3">Movie Ceritificates</div>
                    <img
                      className="img-2"
                      alt="Feather icon users"
                      src={require('../assets/img/feather-icon-users.svg').default}
                    />
                  </div>
                  <img
                    className="feather-icon-chevron"
                    alt="Feather icon chevron"
                    src={require('../assets/img/feather-icon-chevron-down-2.svg').default}
                  />
                </div>
              </NavLink>
              <NavLink to="/Movielang" activeClassName="active">
                <div className="element-navigations-menus-10">
                  <div className="element-navigations-menus-3">
                    <div className="text-wrapper-3">Movie Language</div>
                    <img
                      className="img-2"
                      alt="Feather icon users"
                      src={require('../assets/img/feather-icon-users.svg').default}
                    />
                  </div>
                  <img
                    className="feather-icon-chevron"
                    alt="Feather icon chevron"
                    src={require('../assets/img/feather-icon-chevron-down-2.svg').default}
                  />
                </div>
              </NavLink>
              <NavLink to="/Movies" activeClassName="active">
                <div className="element-navigations-menus-11">
                  <div className="element-navigations-menus-3">
                    <div className="text-wrapper-3">Movies</div>
                    <img
                      className="img-2"
                      alt="Feather icon users"
                      src={require('../assets/img/feather-icon-users.svg').default}
                    />
                  </div>
                  <img
                    className="feather-icon-chevron"
                    alt="Feather icon chevron"
                    src={require('../assets/img/feather-icon-chevron-down-2.svg').default}
                  />
                </div>
              </NavLink>
              <NavLink to="/Moviecast" activeClassName="active">
                <div className="element-navigations-menus-12">
                  <div className="element-navigations-menus-3">
                    <div className="text-wrapper-3">Movie Casts</div>
                    <img
                      className="img-2"
                      alt="Feather icon users"
                      src={require('../assets/img/feather-icon-users.svg').default}
                    />
                  </div>
                  <img
                    className="feather-icon-chevron"
                    alt="Feather icon chevron"
                    src={require('../assets/img/feather-icon-chevron-down-2.svg').default}
                  />
                </div>
              </NavLink>
              <NavLink to="/Moviecrew" activeClassName="active">
                <div className="element-navigations-menus-13">
                  <div className="element-navigations-menus-3">
                    <div className="text-wrapper-3">Movie Crews</div>
                    <img
                      className="img-2"
                      alt="Feather icon users"
                      src={require('../assets/img/feather-icon-users.svg').default}
                    />
                  </div>
                  <img
                    className="feather-icon-chevron"
                    alt="Feather icon chevron"
                    src={require('../assets/img/feather-icon-chevron-down-2.svg').default}
                  />
                </div>
              </NavLink>


              <NavLink to="/Addon" activeClassName="active">
                <div className="element-navigations-menus-14">
                  <div className="element-navigations-menus-3">
                    <div className="text-wrapper-3">Addons</div>
                    <img
                      className="img-2"
                      alt="Feather icon users"
                      src={require('../assets/img/feather-icon-users.svg').default}
                    />
                  </div>
                  <img
                    className="feather-icon-chevron"
                    alt="Feather icon chevron"
                    src={require('../assets/img/feather-icon-chevron-down-2.svg').default}
                  />
                </div>
              </NavLink>

              <NavLink to="/Coupons" activeClassName="active">
                <div className="element-navigations-menus-15">
                  <div className="element-navigations-menus-3">
                    <div className="text-wrapper-3">Coupons</div>
                    <img
                      className="img-2"
                      alt="Feather icon users"
                      src={require('../assets/img/feather-icon-users.svg').default}
                    />
                  </div>
                  <img
                    className="feather-icon-chevron"
                    alt="Feather icon chevron"
                    src={require('../assets/img/feather-icon-chevron-down-2.svg').default}
                  />
                </div>
              </NavLink>
             
            </>
          )}
        </div>

        <div className="img-wrapper">
          <img
            className="frame_img"
            alt="Frame"
            src={require('../assets/img/LogoT.png')}
          />
        </div>
      </div>
    );
  }
}

export default Sidebar;
