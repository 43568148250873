import React, { useState, useEffect } from 'react';
import axios from 'axios';

const ContainerSelection = () => {
  const [containers, setContainers] = useState([]);
  const [selectedSeats, setSelectedSeats] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const [transactionId, setTransactionId] = useState(''); // To store transaction ID
  const user_id = localStorage.getItem('admin_id'); // Assuming user_id is stored in localStorage
  const api_url = localStorage.getItem('api_url');
  const screen_id = localStorage.getItem('screen_id');
  const date = "2024-10-01"; // Example date
  const movie_id = "66de953027fe2fee042202d8"; // Example movie ID
  const timeslot_id = "66de968b27fe2fee042202e2"; // Example timeslot ID

  useEffect(() => {
    const fetchContainersAndSeats = async () => {
      try {
        const theatre_id = localStorage.getItem('theatre_id');
        const response = await axios.get(`${api_url}/containers1/${theatre_id}/${screen_id}/${date}/${movie_id}/${timeslot_id}`);
        setContainers(response.data.data);
      } catch (error) {
        console.error('Error fetching containers or seats:', error);
      }
    };
    fetchContainersAndSeats();
  }, []);

  const handleSeatSelection = (containerId, seatId, price) => {
    const isSelected = selectedSeats.some(seat => seat.seatId === seatId);
    const numericPrice = Number(price);

    if (isSelected) {
      setSelectedSeats(prevSelectedSeats => prevSelectedSeats.filter(seat => seat.seatId !== seatId));
      setTotalPrice(prevTotalPrice => prevTotalPrice - numericPrice);
    } else {
      setSelectedSeats(prevSelectedSeats => [...prevSelectedSeats, { containerId, seatId }]);
      setTotalPrice(prevTotalPrice => prevTotalPrice + numericPrice);
    }
  };

  const groupSeatsByRow = (seats) => {
    const rows = {};
    seats.forEach((seat) => {
      const rowLetter = seat.seat_number.charAt(0);
      if (!rows[rowLetter]) {
        rows[rowLetter] = [];
      }
      rows[rowLetter].push(seat);
    });
    return rows;
  };

  const handleBookNow = async () => {
    if (selectedSeats.length === 0) {
      alert('Please select at least one seat.');
      return;
    }

    try {
      // Step 1: Call the paymentorder API to create an order and transaction
      const paymentResponse = await axios.post(`${api_url}/paymentorder`, {
        tot_amt: totalPrice,
        transactionid: transactionId
      });
      const { transactionid, orderId } = paymentResponse.data;
      setTransactionId(transactionid);

      // Step 2: Initiate Razorpay payment
      const options = {
        key: 'rzp_test_JFlMQ0tOgzb3j8', // Example Razorpay test key
        amount: totalPrice * 100, // amount in paise
        currency: 'INR',
        name: 'Medcall',
        description: 'Medcall Transactions',
        image: 'https://example.com/your_logo',
        order_id: orderId,
        handler: async (response) => {
          const { razorpay_payment_id } = response;

          // Step 3: Call the savetransactions API after payment success
          const saveTransactionResponse = await axios.post(`${api_url}/savetransactions`, {
            user_id,
            payment_success_id: razorpay_payment_id,
            status: 'booked',
            transactionid: razorpay_payment_id,
            total_amt: totalPrice
          });

          if (saveTransactionResponse.data) {
            // Step 4: Call the bookings_confirm API after transaction is saved
            const seat_ids = selectedSeats.map(seat => seat.seatId).join(',');

            const confirmBookingResponse = await axios.post(`${api_url}/bookings_confirm`, {
              screen_id,
              timeslot_id,
              movie_id,
              seat_ids,
              total_amount: totalPrice,
              transaction_id: razorpay_payment_id,
              date,
              user_id
            });

            if (confirmBookingResponse.data) {
              alert('Booking confirmed successfully!');
              window.location.reload(); // Optionally refresh or redirect
            } else {
              alert('Failed to confirm booking.');
            }
          } else {
            alert('Failed to save transaction.');
          }
        },
        prefill: {
          name: "Harsanth", // Example prefill data
          email: "harsanthjack@gmail.com",
          contact: "8667040195"
        },
        theme: {
          color: '#3399cc'
        }
      };

      const rzp1 = new window.Razorpay(options);
      rzp1.open();
    } catch (error) {
      console.error('Error during booking process:', error);
      alert('An error occurred while processing your booking.');
    }
  };

  return (
    <div>
      <div className="container-list">
        {containers.map((container) => (
          <div key={container._id} className="container-item">
            <p>{container.container_name} - ₹{container.price}</p>
            <div className="seats-grid">
              {container.seats && container.seats.length > 0 ? (
                Object.entries(groupSeatsByRow(container.seats)).map(([rowLetter, rowSeats]) => (
                  <div key={rowLetter} className="seat-row">
                    <div className="row-label">{rowLetter}</div>
                    {rowSeats.map((seat) => (
                      seat.seat_number.toLowerCase().includes('break') ? (
                        <div key={seat.seat_id} className="seat break"></div>
                      ) : (
                        <div
                          key={seat.seat_id}
                          className={`seat ${selectedSeats.some(s => s.seatId === seat.seat_id) ? 'selected' : ''} ${seat.status === 1 ? 'booked' : seat.status === 2 ? 'available' : ''}`}
                          onClick={() => seat.status === 2 && handleSeatSelection(container._id, seat.seat_id, container.price)}
                          style={{
                            cursor: seat.status === 2 ? 'pointer' : 'not-allowed',
                            backgroundColor: seat.status === 1 ? 'gray' : seat.status === 2 ? '' : ''
                          }}
                        >
                          {seat.seat_number}
                        </div>
                      )
                    ))}
                  </div>
                ))
              ) : (
                <p>No seats available for this container</p>
              )}
            </div>
          </div>
        ))}
      </div>
      <div className="booking-summary">
        <p>Total Price: ₹{totalPrice}</p>
        <button onClick={handleBookNow}>Book Now</button>
      </div>
    </div>
  );
};

export default ContainerSelection;
