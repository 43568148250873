import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import axios from 'axios';


class InnerHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      api_url:localStorage.getItem('api_url'),
      admin_id:localStorage.getItem('admin_id'),
        data:[], 
        data1:[],
        data2:[],
        data3:[]    
    };     
    
  }
  componentDidMount() {   
    
    axios({
      method: 'get',
      url: this.state.api_url+'/getCounts/'+this.state.admin_id,                
      
    }).then(res => {
      const data = res.data;
     // console.log(data);
      this.setState({                         
              data: data.counts,             
            });
    });

    axios({
      method: 'get',
      url: this.state.api_url+'/getCountsuser/'+this.state.admin_id,               
      
    }).then(res => {
      const data1 = res.data;
     // console.log(data);
      this.setState({                         
              data1: data1.counts1,             
            });
    });

    axios({
      method: 'get',
      url: this.state.api_url+'/getCountsStores',                
      
    }).then(res => {
      const data1 = res.data;
     // console.log(data);
      this.setState({                         
              data2: data1.counts1,             
            });
    });


    axios({
      method: 'get',
      url: this.state.api_url+'/getCountsAgents',                
      
    }).then(res => {
      const data1 = res.data;
     // console.log(data);
      this.setState({                         
              data3: data1.counts1,             
            });
    });
    

}
  render() {
    return  <div className="innerHeader">
   
  </div>;
  }
}

export default InnerHeader;





